import usersPaginationTemplate from "../../../directives/components/pagination/pagination.template.html";
import { IFilterService, ITemplateCacheService } from "angular";


interface TranslatedProperty {
  name: string;
  show: boolean;
}

export default class UsersHelpSidebarCtrl {
  private paginationTemplateUrl: string;
  private paginateEmployees: any;
  private paginateProjects: any;
  private projectsLazyLoading: boolean;
  private employeesLazyLoading: boolean;
  private projects: any[];
  private employees: any[];
  private unrefreshedProjects: any[];
  private loading: boolean;
  private loginConfirmedListener: () => void;
  private addProjectListener: () => void;
  private addEmployeeListener: () => void;

  private projectListAttributes: { title: { title: string; show: boolean } };
  private managerAttributes: {
    name: TranslatedProperty;
    company: TranslatedProperty;
    reminder: TranslatedProperty;
  };

  static $inject = [
    "Session",
    "ProjectsFactory",
    "EmployeesFactory",
    "makeCollectionProxy",
    "UsersFactory",
    "$rootScope",
    "$filter",
    "MgPaginateService",
    "$templateCache",
  ];

  constructor(
    private Session: any,
    public Project: any,
    public Employee: any,
    private makeCollectionProxy: (loader: () => any, stateParams: any) => Promise<any[]>,
    private User: any,
    private $rootScope: IExtendedRootScopeService,
    private $filter: IFilterService,
    private MgPaginateService: any,
    private $templateCache: ITemplateCacheService
  ) {
    this.initializeUsersSidebar = this.initializeUsersSidebar.bind(this);

    this.paginationTemplateUrl = "paginationTemplate";
    $templateCache.put(this.paginationTemplateUrl, usersPaginationTemplate);
    this.projectListAttributes = {
      title: {
        title: this.$filter("translate")("PROJECT_TITLE"),
        show: true,
      },
    };
    this.managerAttributes = {
      name: {
        name: this.$filter("translate")("NAME"),
        show: true,
      },
      company: {
        name: this.$filter("translate")("COMPANY"),
        show: true,
      },
      reminder: {
        name: this.$filter("translate")("NEXT_CONTACT_REMINDER"),
        show: true,
      },
    };
  }

  public isEmpty(field) {
    return !((field && _.has(field.meta, "totalCount")) || !_.isEmpty(field));
  }

  public diffCollections(newlist, oldlist) {
    var ids = _.difference(_.pluck(newlist, "id"), _.pluck(oldlist, "id"));
    return _.filter(newlist, function (item) {
      return _.contains(ids, item.id);
    });
  }

  public updateProject(project, status, index) {
    this.loading = true;
    new this.Project({ id: project.id, projectStatus: status, saveBasics: true }).save().then((result) => {
      project.snapshot();
      this.$rootScope.$broadcast("project_action_changed_" + project.id);

      this.unrefreshedProjects.splice(index, 1);
      this.loading = false;
    });
  }

  public onOver(e, ui) {
    angular.element(e.target).addClass("disabled");
  }

  public onOut(e) {
    angular.element(e.target).removeClass("disabled");
  }

  public isDataDropArea(project) {
    return !project.initiativeApplicationProject;
  }

  public projectLonglistWatcherInit(project, childScope) {
    project.longlist = angular.copy(_.pluck(project.candidatesProjects, "candidate")) || [];

    childScope.$watchCollection("project.longlist", (newlistItems, oldlistItems, scope) => {
      if (_.isEmpty(this.diffCollections(newlistItems, oldlistItems))) {
        return;
      }

      newlistItems = this.diffCollections(newlistItems, oldlistItems);

      _.each(newlistItems, (listItem) => {
        project.addCandidate(listItem).then((result) => {
          window.alert(
            this.$filter("translate")("CANDIDATE_WAS_ADDED_TO_PROJECT_NOTICE", {
              name: listItem.fullNameWithTitle,
              project: project.title,
            })
          );
        });
      });
    });
  }

  public initializeUsersSidebar(forceReload?: boolean) {
    if (forceReload || this.isEmpty(this.Session.user.projects)) {
      if (forceReload) {
        this.projects = [];
      }
      this.projectsLazyLoading = true;
      this.makeCollectionProxy(this.Project.query.bind(this.Project), { limit: 5, my_active_projects: true }).then(
        (projects: any[]) => {
          this.projects = projects;
          this.Session.user.projects = this.projects;

          this.paginateProjects.setData(this.projects);
          this.paginateProjects.setTotalItems(this.projects.length);

          this.projectsLazyLoading = false;
        }
      );
    } else {
      this.projects = this.Session.user.projects;

      this.paginateProjects.setData(this.projects);
      this.paginateProjects.setTotalItems(this.projects.length);
    }

    if (forceReload || this.isEmpty(this.Session.user.employees)) {
      if (forceReload) {
        this.employees = [];
      }
      this.employeesLazyLoading = true;
      this.makeCollectionProxy(this.Employee.query.bind(this.Employee), { limit: 5, my_employees: true }).then(
        (employees) => {
          this.employees = employees;
          this.Session.user.employees = this.employees;

          this.paginateEmployees.setData(this.employees);
          this.paginateEmployees.setTotalItems(this.employees.length);

          var today = new Date();
          _.each(this.employees, function (employee) {
            employee.shouldBeContacted = today > employee.nextReminderWillBeAt;
          });

          this.employeesLazyLoading = false;
        }
      );
    } else {
      this.employees = this.Session.user.employees;
      this.paginateEmployees.setData(this.employees);
      this.paginateEmployees.setTotalItems(this.employees.length);
    }

    if (forceReload || this.isEmpty(this.Session.user.projectsOneMonthInactive)) {
      if (forceReload) {
        this.unrefreshedProjects = [];
      }
      this.projectsLazyLoading = true;
      this.User.get(this.Session.user.id).then((user) => {
        angular.extend(this.Session.user, user);
        this.unrefreshedProjects = user.projectsOneMonthInactive;

        this.projectsLazyLoading = false;
      });
    } else {
      this.unrefreshedProjects = this.Session.user.projectsOneMonthInactive;
    }
  }

  public $onInit() {
    this.paginateEmployees = new this.MgPaginateService();
    this.paginateProjects = new this.MgPaginateService();

    this.projectsLazyLoading = false;
    this.employeesLazyLoading = false;

    this.initializeUsersSidebar();

    this.loginConfirmedListener = this.$rootScope.$on(
      "event:auth-loginConfirmed",
      _.partial(this.initializeUsersSidebar, true)
    );
    this.addProjectListener = this.$rootScope.$on("addProject", _.partial(this.initializeUsersSidebar, true));
    this.addEmployeeListener = this.$rootScope.$on("addEmployee", _.partial(this.initializeUsersSidebar, true));
  }

  public $onDestroy() {
    this.loginConfirmedListener();
    this.addProjectListener();
    this.addEmployeeListener();
  }
}
